import { http } from '@/http/axios.js'

// 列表
export function getListAPI(data) {
  return http({
    url: `/api/manage/user/invoice/list`,
    method: 'POST',
    data
  })
}


export function getDetailAPI(id) {
  return http({
    url: `/api/manage/user/invoice/detail`,
    method: 'post',
    data: { id }
  })
}

export function uploadInvoiceAPI(data) {
  return http({
    url: `/api/manage/user/invoice/uploadInvoice`,
    method: 'post',
    data
  })
}

