<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form
          ref="form"
          inline
          :model="searchData"
          label-width=""
      >
        <el-form-item label="订单号">
          <el-input
              size="small"
              v-model="searchData.order_no"
              placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select
              style="width: 200px"
              v-model="searchData.pay_type"
              placeholder="请选择"
              size="mini"
              clearable
          >
            <el-option
                label="微信支付"
                :value="20"
            ></el-option>
            <el-option
                label="微信扫码支付"
                :value="40"
            ></el-option>
            <el-option
                label="支付宝扫码支付"
                :value="50"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input
              size="small"
              v-model="searchData.product_name"
              placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getList"
          >搜索
          </el-button>
          <el-button
              type="info"
              size="mini"
              icon="el-icon-refresh"
              @click="reset"
          >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
          prop="name"
          label="抬头"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="tax_number"
          label="税号"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="contact_person"
          label="联系人"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱"
          width="width"
          min-width="40px"
      >
      </el-table-column>

      <el-table-column
          prop="product_info"
          label="订单信息"
          width="width"
          min-width="60px"
      >
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row">
              <span>订单编号：</span>
              <span>{{ row?.order?.order_no }}</span>
            </div>
            <div class="row">
              <span>产品名称：</span>
              <span>{{ row?.order?.product_name }} {{ row?.order?.order_price }}元/{{ row?.order?.year }}年</span>
            </div>
            <div class="row">
              <span>支付方式：</span>
              <span>{{ row?.order?.pay_type_dsc }}</span>
            </div>
          </div>
        </template>
      </el-table-column>


      <el-table-column
          prop="fapiao"
          label="发票"
          width="width"
          min-width="20px"
      >
        <template slot-scope="{ row }">
          <span v-if="! row.download_url">暂无</span>
          <a v-else :href="row.download_url" download="true">下载</a>
        </template>
      </el-table-column>

      <el-table-column
          prop="created_at"
          label="申请日期"
          width="width"
          min-width="40px"
      ></el-table-column>
      <el-table-column
          label="操作"
          width="150px"
          fixed="right"
      >
        <template slot-scope="{ row }">
          <el-link
              style="margin: 0 10px"
              type="primary"
              :underline="false"
              @click="showUploadInvoice(row)"
          >上传发票
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :total="total"
        :page.sync="searchData.page"
        :limit.sync="searchData.pagesize"
        @pagination="getList"
    />

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <uploader-video @update:videoUrl="uploadInvoice"></uploader-video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import {getListAPI, getDetailAPI, uploadInvoiceAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'
import UploaderVideo from "@/components/uploader/uploader-video.vue";

export default {
  name: 'Index',
  components: {UploaderVideo},
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{required: true, message: '该项不能为空', trigger: 'blur'}],
      show_detail: false,
      show_review: false,
      dialogVisible: false,
      list: [],
      city_list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        order_no: '',
        product_name: '',
        pay_type: null,
        pay_status: null
      },
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      },
      uploadInvoiceFormData: {
        id: 0,
        download_url: ''
      },
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    // 详情
    async handleDetail({id}) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    handleClose() {

    },
    uploadInvoice(res) {
      this.uploadInvoiceFormData.download_url = res.url
      uploadInvoiceAPI(this.uploadInvoiceFormData).then(res => {
        this.$message.success('上传成功')
        this.getList()
        this.dialogVisible = false
        this.uploadInvoiceFormData = {
          id: 0,
          download_url: ''
        }
      })
    },
    showUploadInvoice({id}) {
      this.uploadInvoiceFormData.id = id
      this.dialogVisible = true
    },
    async getList() {
      // 获取get 参数
      let params = {...this.searchData}
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }

  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}

.user-box {
  .row {
    display: flex;

    span {
      &:nth-child(1) {
        width: 72px;
        text-align: right;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}
</style>
